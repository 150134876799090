import { render, staticRenderFns } from "./CuttingList.vue?vue&type=template&id=563f4cde&scoped=true&"
import script from "./CuttingList.vue?vue&type=script&lang=js&"
export * from "./CuttingList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563f4cde",
  null
  
)

export default component.exports